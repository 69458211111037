var render = function render(){
  var _vm$value, _vm$value2, _vm$value3, _vm$value3$thumb, _vm$value4, _vm$value5, _vm$value6, _vm$value7, _vm$value7$labels, _vm$value7$labels$inc, _vm$value8, _vm$value8$labels, _vm$value8$labels$inc, _vm$value9, _vm$value9$flags, _vm$value10, _vm$value10$flags, _vm$value11, _vm$value11$category, _vm$value12, _vm$value12$category, _vm$value13, _vm$value13$category, _vm$value14;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    staticClass: "product-item",
    class: {
      'product-item--slide': _vm.slide
    },
    attrs: {
      "cols": _vm.cols,
      "sm": _vm.sm,
      "md": _vm.md,
      "lg": _vm.lg
    }
  }, [_c('div', {
    staticClass: "product-item__inner"
  }, [_c('like-btn', {
    attrs: {
      "params": {
        _product: (_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value._id
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var active = _ref.active,
          toggle = _ref.toggle;
        return [_c('v-btn', {
          staticClass: "product-item__favorite",
          attrs: {
            "icon": "",
            "small": "",
            "color": "grey lighten-3"
          },
          on: {
            "!click": function ($event) {
              $event.stopPropagation();
              return toggle.apply(null, arguments);
            }
          }
        }, [active ? _c('span', {
          staticClass: "material-icons-outlined font-size-20 font-size-md-24 primary--text"
        }, [_vm._v("favorite")]) : _c('span', {
          staticClass: "material-icons-outlined font-size-20 font-size-md-24 grey--text text--darken-4"
        }, [_vm._v("favorite_border")])])];
      }
    }])
  }), _c('router-link', {
    attrs: {
      "to": `/shop/products/${(_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2._id}`
    }
  }, [_c('div', {
    staticClass: "product-item__img",
    style: `background-image:url(${(_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : (_vm$value3$thumb = _vm$value3.thumb) === null || _vm$value3$thumb === void 0 ? void 0 : _vm$value3$thumb.url});`
  }, [!((_vm$value4 = _vm.value) !== null && _vm$value4 !== void 0 && _vm$value4.sells) || (_vm$value5 = _vm.value) !== null && _vm$value5 !== void 0 && _vm$value5.stockEnabled && ((_vm$value6 = _vm.value) === null || _vm$value6 === void 0 ? void 0 : _vm$value6.stock) == 0 ? _c('div', {
    staticClass: "product-item__badge"
  }, [_vm._v("Sold Out")]) : _vm._e(), _c('div', {
    staticClass: "product-item__points"
  }, [(_vm$value7 = _vm.value) !== null && _vm$value7 !== void 0 && (_vm$value7$labels = _vm$value7.labels) !== null && _vm$value7$labels !== void 0 && (_vm$value7$labels$inc = _vm$value7$labels.includes) !== null && _vm$value7$labels$inc !== void 0 && _vm$value7$labels$inc.call(_vm$value7$labels, 'new') ? [_c('span', {
    staticClass: "product-item__point product-item__point--pink"
  }, [_vm._v("New")])] : _vm._e(), (_vm$value8 = _vm.value) !== null && _vm$value8 !== void 0 && (_vm$value8$labels = _vm$value8.labels) !== null && _vm$value8$labels !== void 0 && (_vm$value8$labels$inc = _vm$value8$labels.includes) !== null && _vm$value8$labels$inc !== void 0 && _vm$value8$labels$inc.call(_vm$value8$labels, 'digital') ? [_c('span', {
    staticClass: "product-item__point product-item__point--violet"
  }, [_vm._v("Digital")])] : _vm._e()], 2)]), _c('div', {
    staticClass: "product-item__con"
  }, [(_vm$value9 = _vm.value) !== null && _vm$value9 !== void 0 && (_vm$value9$flags = _vm$value9.flags) !== null && _vm$value9$flags !== void 0 && _vm$value9$flags.length ? _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
  }, [_vm._v(" " + _vm._s((_vm$value10 = _vm.value) === null || _vm$value10 === void 0 ? void 0 : (_vm$value10$flags = _vm$value10.flags) === null || _vm$value10$flags === void 0 ? void 0 : _vm$value10$flags.map(function (text) {
    return `[${text}]`;
  }).join(" ")) + " ")]) : _vm._e(), (_vm$value11 = _vm.value) !== null && _vm$value11 !== void 0 && (_vm$value11$category = _vm$value11.category) !== null && _vm$value11$category !== void 0 && _vm$value11$category.name ? [_c('v-chip', {
    attrs: {
      "x-small": "",
      "dark": "",
      "color": ((_vm$value12 = _vm.value) === null || _vm$value12 === void 0 ? void 0 : (_vm$value12$category = _vm$value12.category) === null || _vm$value12$category === void 0 ? void 0 : _vm$value12$category.color) || 'grey'
    }
  }, [_vm._v(" " + _vm._s((_vm$value13 = _vm.value) === null || _vm$value13 === void 0 ? void 0 : (_vm$value13$category = _vm$value13.category) === null || _vm$value13$category === void 0 ? void 0 : _vm$value13$category.name) + " ")])] : _vm._e(), _c('div', {
    staticClass: "product-item__tit mt-4px"
  }, [_c('span', [_vm._v(_vm._s((_vm$value14 = _vm.value) === null || _vm$value14 === void 0 ? void 0 : _vm$value14.name))])])], 2)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }