<template>
    <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" class="product-item" :class="{ 'product-item--slide': slide }">
        <div class="product-item__inner">
            <like-btn :params="{ _product: value?._id }">
                <template #activator="{ active, toggle }">
                    <v-btn icon small color="grey lighten-3" class="product-item__favorite" @click.stop.capture="toggle">
                        <span v-if="active" class="material-icons-outlined font-size-20 font-size-md-24 primary--text">favorite</span>
                        <span v-else class="material-icons-outlined font-size-20 font-size-md-24 grey--text text--darken-4">favorite_border</span>
                    </v-btn>
                </template>
            </like-btn>
            <router-link :to="`/shop/products/${value?._id}`">
                <div class="product-item__img" :style="`background-image:url(${value?.thumb?.url});`">
                    <div v-if="!value?.sells || (value?.stockEnabled && value?.stock == 0)" class="product-item__badge">Sold Out</div>
                    <div class="product-item__points">
                        <template v-if="value?.labels?.includes?.('new')">
                            <span class="product-item__point product-item__point--pink">New</span>
                        </template>
                        <template v-if="value?.labels?.includes?.('digital')">
                            <span class="product-item__point product-item__point--violet">Digital</span>
                        </template>
                    </div>
                </div>
                <div class="product-item__con">
                    <div class="txt txt--sm txt--dark font-weight-bold mb-4px" v-if="value?.flags?.length">
                        {{ value?.flags?.map((text) => `[${text}]`).join(" ") }}
                    </div>
                    <template v-if="value?.category?.name">
                        <v-chip x-small dark :color="value?.category?.color || 'grey'">
                            {{ value?.category?.name }}
                        </v-chip>
                    </template>
                    <div class="product-item__tit mt-4px">
                        <span>{{ value?.name }}</span>
                    </div>
                    <!-- <div class="mt-10px mt-md-14px">
                        <div class="d-flex align-center">
                            <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="+(value?.meta?.reviews?.star__average || 0).toFixed(1)" />
                            <span class="material-icons-outlined primary--text">star</span>
                            <span class="font-size-14 font-weight-medium grey--text">{{ (value?.meta?.reviews?.star__average || 0).toFixed(1) }}</span>
                            <span class="font-size-14 grey--text text--lighten-1 pl-4px">({{ value?.meta?.reviews?.count__total?.format?.() || 0 }})</span>
                        </div>
                    </div> -->
                    <!-- <div class="mt-8px mt-md-12px" v-if="value.type != PRODUCT_TYPES.VARIETY_BUNDLE.value">
                        <span v-if="value?.discountPrice" class="font-size-14 grey--text text-decoration-line-through">{{ value?.price?.format?.() }}</span>
                        <em v-if="!value?.sells || (value?.stockEnabled && value?.stock == 0)" class="font-size-14 font-size-md-16 grey--text text--lighten-1">품절</em>
                        <div v-else class="d-flex flex-wrap align-center tit tit--xs font-weight-bold">
                            <span v-if="value?.discountRate" class="secondary--text mr-4px mr-md-8px">{{ value?.discountRate }}%</span>
                            <span class="d-flex flex-wrap align-center grey--text text--darken-4">{{ value?.salePrice?.format?.() }}<small class="font-size-14 font-weight-regular pl-2px">원</small></span>
                        </div>
                    </div> -->
                    <!-- <div class="mt-4px mt-md-8px">
                        <v-row class="row--xxs">
                            <v-col v-if="value?.NEW_ARRIVAL" cols="auto"><v-chip small color="grey lighten-5" text-color="primary">신제품</v-chip></v-col>
                            <v-col v-if="value?.POPULAR" cols="auto"><v-chip small color="grey lighten-5" text-color="secondary">POPULAR</v-chip></v-col>
                            <v-col cols="auto"><v-chip small color="grey lighten-5" text-color="grey darken-4">당일배송</v-chip></v-col>
                        </v-row>
                    </div> -->
                </div>
            </router-link>
        </div>
    </v-col>
</template>

<script>
import { PRODUCT_TYPES } from "@/assets/variables";

import LikeBtn from "@/components/client/like/like-btn.vue";

export default {
    components: {
        LikeBtn,
    },
    props: {
        value: { type: Object, default: null },

        cols: { type: String, default: "6" },
        sm: { type: String, default: "4" },
        md: { type: String, default: "4" },
        lg: { type: String, default: "3" },
        slide: { type: Boolean, default: false },
    },
    data: () => ({
        PRODUCT_TYPES,
    }),
};
</script>

<style lang="scss" scoped>
.product-item.product-item--slide {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
}
</style>
