<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <template slot="tit">{{ groupText }}</template>

            <template v-if="items.length">
                <v-row>
                    <v-col v-for="(item, index) in items" :key="item._id" cols="12">
                        <v-card :to="`${$route.path}/${item._id}`">
                            <v-img cover :src="item?.thumb?.url" :aspect-ratio="$vuetify.breakpoint.lgAndUp ? 1330 / 340 : 300 / 180">
                                <div class="d-flex align-center h-100 pa-20px py-md-30px px-lg-60px" style="background-color: rgba(0, 0, 0, 0.5)">
                                    <div class="white--text">
                                        <v-chip small pill :color="index % 2 === 0 ? 'primary' : 'secondary'" class="font-weight-bold" v-if="item?.label">{{ item?.label }}</v-chip>
                                        <div class="tit tit--lg mt-4px mt-md-8px">{{ item?.title }}</div>
                                        <div class="font-size-14 font-size-md-16 mt-6px mt-md-12px" v-if="item?.summary">{{ item?.summary }}</div>
                                    </div>
                                </div>
                            </v-img>
                        </v-card>
                    </v-col>
                </v-row>

                <div class="pagination-wrap">
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </div>
            </template>
            <template v-else-if="!loading">
                <div class="text-center">
                    <div class="mb-18px mb-md-24px">
                        <v-icon size="60px" color="primary">mdi-calendar-star</v-icon>
                    </div>
                    <div class="tit tit--sm">등록된 {{ groupText }} 항목이 없습니다.</div>
                    <div class="txt txt--sm mt-8px">제품 목록을 확인해주세요.</div>

                    <div class="btn-wrap btn-wrap--lg">
                        <v-row justify="center" class="row--sm">
                            <v-col cols="6" sm="auto">
                                <v-btn v-bind="{ ...btn_primary, ...$attrs }" to="/shop" class="v-size--xx-large w-100 min-w-sm-200px">쇼핑 계속하기</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { EXHIBITION_GROUPS } from "@/assets/variables";
import { btn_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

const groups = Object.values(EXHIBITION_GROUPS);

export default {
    components: {
        ClientPage,
        PageSection,
    },
    data: () => ({
        btn_primary,

        exhibitions: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        items() {
            return [...this.exhibitions];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        group() {
            return this.$route.query.group || groups[0].value;
        },
        groupText() {
            return EXHIBITION_GROUPS[this.group]?.text;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            query.group = this.group;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },
        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, exhibitions } = await api.v1.shop.exhibitions.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.exhibitions = exhibitions.map((item, index) => ({ index: summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
